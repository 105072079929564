import "kalend/dist/styles/index.css"; // import styles
import { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Link,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { Authenticated } from "ra-core";
import { DatePicker } from "@mui/x-date-pickers";
import {CookService} from "../../services/CookServices";
import '../OmsTimeTable/index.css';
import {OneMealService} from "../../services/OneMealService";
import {TrialsService} from "../../services/TrialServices";
import UserBundleService from "../../services/BundleService";
import {OneMealServiceStatus} from "../../enums/OneMealServiceStatus";
import { CookReferenceModelName } from "../../enums/CookReferenceModelName";
import { useRecordContext } from "react-admin";
import { retrieveFromLocalStorage, saveOrUpdateLocalStorage } from "../../utils/utils";
import { Calendar, momentLocalizer } from "react-big-calendar";
import CircularLoader from "../Common/CircularLoader";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const cookService = new CookService();
const oneMealService = new OneMealService();
const trialsService = new TrialsService();
const userBundleService = new UserBundleService();
// const currentDate = localStorage.getItem('omsTimeTableDateFilter') ? moment(localStorage.getItem('omsTimeTableDateFilter')).toDate() : moment().toDate();

const legends = [
  { color: 'darkgrey', label: 'BLOCKED, Leaves/CK_BLOCKED' },
  { color: '#43a047', label: 'SERVICES FULFILLED' },
  { color: '#1d89e4', label: 'COOK_ALLOCATED, COOK_CONFIRMED before SERVICES Start time' },
  { color: '#e53935', label: 'COOK_ALLOCATED, COOK_CONFIRMED after SERVICES Start time' },
  { color: 'black', label: 'PENDING SERVICES' },
];
interface CookLeaveInterface {
    summary: string,
    id: any,
    start: any,
    startAt:string,
    endAt:string,
    end: any,
    color: any,
    cookId: any,
    referenceId: string,
    referenceModelName: string,
    isLeave: boolean,
    cookDetails: {},
    title:string,
};

interface CookLeaveDataInterface {
    [date: string]: CookLeaveInterface[];
}
const CustomToolbar = (props: any) => {
  return (
    <div className="rbc-toolbar">
      <span className="rbc-toolbar-label">{props?.label}</span> {/* Display current date */}
    </div>
  );
};

const CookPartTimeCalendar = (props: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [dialog, setDialog] = useState<boolean>(false);
    const [initialDate, setInitialDate] = useState<Date>(moment().toDate());
    const [finalDate, setFinalDate] = useState<Date>(moment(initialDate).add(7,'days').toDate());
    const [details, setDetails] = useState<any>();
    const [cookOmsMapping, setCookOmsMapping] = useState<any>({});
    
    const record = useRecordContext();
    const eventStyleGetter = (event: any) => {
      let backgroundColor = event.color || "#3174ad";
      let color = ['#e53935', '#1d89e4', 'black'].includes(event.color) ? 'white' : 'black' // Default color
      let border = '1px solid white'
  
      return {
        style: {
          backgroundColor,
          color,
          border,
        },
      };
    };
    function mergeContinuousTimeSlots(timeSlots: any[]) {
      if (!timeSlots.length) return [];
  
      // Sort the array based on the `start` time
      timeSlots.sort(
        (a: { start: Date }, b: { start: Date }) => +new Date(a.start) - +new Date(b.start)
      );
  
      const mergedSlots = [];
      let currentSlot = timeSlots[0];
  
      for (let i = 1; i < timeSlots.length; i++) {
        const currentEnd = moment(currentSlot.end);
        const nextStart = moment(timeSlots[i].start);
  
        // Destructure necessary fields for model and ID checks
        const { referenceModelName: currentModelName, referenceId: currentReferenceId } = currentSlot;
        const { referenceModelName: nextModelName, referenceId: nextReferenceId } = timeSlots[i];
  
        // Check if slots are continuous and belong to the same reference
        if (
          currentEnd.isSameOrAfter(nextStart) &&
          currentModelName === nextModelName &&
          currentReferenceId === nextReferenceId
        ) {
          // Merge the current slot with the next slot
          currentSlot.end = timeSlots[i].end;
        } else {
          // Modify `currentSlot.start` slightly to adjust for merging
          currentSlot.start = moment(currentSlot.start).add(0.001, "hours").toDate();
          mergedSlots.push(currentSlot); // Push the merged slot
          currentSlot = timeSlots[i]; // Start a new slot
        }
      }
  
      // Final adjustment for the last slot
      if (currentSlot?.isLeave === true) {
        currentSlot.end = moment(currentSlot.end).subtract(0.001, "hours").toDate();
      }
  
      mergedSlots.push(currentSlot); // Add the last slot
  
      return mergedSlots;
    }

    const getColor = (eachSlot: any) => {
      if (eachSlot?.referenceModelName === CookReferenceModelName.ONE_MEAL_SERVICE ||
        eachSlot?.referenceModelName === CookReferenceModelName.COOK_SUBSCRIPTION_V3 ||
        eachSlot?.referenceModelName === CookReferenceModelName.TRIALS_V3) {
        const currentDate = moment(new Date(), "YYYY-MM-DD HH:mm:ss");
        const slotDateTime = moment(
          moment(eachSlot.date).format('YYYY-MM-DD') + "T" + moment(eachSlot.timeSlot, "h:mm A").format("HH:mm"), "YYYY-MM-DD HH:mm:ss"
        ).format();
        if (eachSlot?.serviceStatus === OneMealServiceStatus.FULFILLED) {
          return '#43a047';
        }
        if ([OneMealServiceStatus.COOK_ALLOCATED, OneMealServiceStatus.COOK_CONFIRMED].includes(eachSlot?.serviceStatus) && !currentDate.isAfter(slotDateTime)) {
          return '#1d89e4'
        }
        if ([OneMealServiceStatus.COOK_ALLOCATED, OneMealServiceStatus.COOK_CONFIRMED].includes(eachSlot?.serviceStatus) && currentDate.isAfter(slotDateTime) && eachSlot?.checkInTime === null) {
          return '#e53935'
        }
        if ([OneMealServiceStatus.ONGOING].includes(eachSlot?.serviceStatus) && eachSlot?.checkOutTime === null) {
          return '#e53935'
        }
        if (eachSlot?.leaveMarked !== null) {
          return '#a9a9a9';
        }
        return "#fdd734";
      } else if (eachSlot?.leaveMarked !== null || eachSlot?.cookLeaveId !== null || (eachSlot?.status === 'CK_BLOCKED' && eachSlot?.referenceModelName === null) || ((eachSlot?.status === 'BLOCKED' && eachSlot?.referenceModelName === null))) {
        return '#a9a9a9';
      } else if (OneMealServiceStatus.COOK_ALLOCATED) {
        return 'black';
      }
    };

    const getAllCooks = useCallback(() => {
        let startDate =  moment(moment(initialDate).format("YYYY-MM-DD")).format("YYYY-MM-DD");
        let endDate = moment(finalDate).format("YYYY-MM-DD");
        let query: any = { startDate: startDate, endDate: endDate, cookId: record?.id };
        setLoading(true);
        cookService.getPartTimeCookCalender(query).then((res) => {
            let tempCookOmsMapping: CookLeaveDataInterface = {};
            for (const [key, val] of Object.entries(res)) {    
                let tempDetails: any = {};
                const timeSlots: any[] = [];
                // eslint-disable-next-line
                (val as any[])?.map((eachSlot: any, ind: number) => {
                    let requiredDetails: CookLeaveInterface = {
                        summary: "",
                        id: NaN,
                        start: "",
                        end: "",
                        startAt: "",
                        endAt: "",
                        color: "",
                        cookId: NaN,
                        referenceId: "",
                        referenceModelName: "",
                        isLeave: false,
                        cookDetails: {},
                        title:'',
                    };
                    requiredDetails.summary = eachSlot?.referenceModelName ? `${eachSlot?.referenceId}_${eachSlot?.referenceModelName}` : eachSlot?.status === 'BLOCKED' ? 'BLOCKED' : 'LEAVE';
                    requiredDetails.id = eachSlot.id;
                    requiredDetails.start = moment(`${eachSlot.date}T${moment(eachSlot.timeSlot, "h:mm A").format("HH:mm")}`).toDate();
                    requiredDetails.end = moment(requiredDetails.start).add(0.5, "hours").toDate();
                    requiredDetails.startAt = moment(eachSlot.date + "T" + moment(eachSlot.timeSlot, "h:mm A").format("HH:mm")).format();
                    requiredDetails.endAt = moment(requiredDetails.startAt).add(0.5, "hours").format();
                    requiredDetails.color = getColor(eachSlot);
                    requiredDetails.cookId = record?.id;
                    requiredDetails.referenceId = eachSlot?.referenceId;
                    requiredDetails.referenceModelName = eachSlot?.referenceModelName;
                    requiredDetails.cookDetails = record?.cookFullProfile;
                    requiredDetails.summary = eachSlot?.referenceModelName ? `${eachSlot?.referenceId}_${eachSlot?.referenceModelName}` : eachSlot?.status === 'CK_BLOCKED' ? 'CK_BLOCKED' : eachSlot?.cookLeaveId ? 'LEAVE' : eachSlot?.status === 'BLOCKED' ? 'BLOCKED' : 'PENDING';
                    requiredDetails.title = eachSlot?.referenceModelName ? `${eachSlot?.referenceId}_${eachSlot?.referenceModelName}` : eachSlot?.status === 'CK_BLOCKED' ? 'CK_BLOCKED' : eachSlot?.cookLeaveId ? 'LEAVE' : eachSlot?.status === 'BLOCKED' ? 'BLOCKED' : 'PENDING';
                    tempDetails = { ...requiredDetails };
                    if(eachSlot?.leaveMarked !== null || eachSlot?.cookLeaveId !== null || (eachSlot?.status === 'BLOCKED' && eachSlot?.referenceModelName === null)){
                        requiredDetails.isLeave = true;
                    }
                    if (eachSlot?.referenceModelName !== null || eachSlot?.leaveMarked !== null || eachSlot?.cookLeaveId !== null || eachSlot?.status === 'BLOCKED') {
                        if(eachSlot?.referenceModelName !== ''){
                            timeSlots.push(requiredDetails);
                        }
                    }
                })
                let mergedSlots = mergeContinuousTimeSlots(timeSlots); 
                console.log(mergedSlots);
                        
                if(mergedSlots.length === 0){
                    tempDetails.start = '';
                    tempDetails.end = '';
                    mergedSlots = [tempDetails];
                }
                if (!tempCookOmsMapping[key]) {
                    tempCookOmsMapping[key] = [...mergedSlots];
                } else {
                    tempCookOmsMapping[key].push(...mergedSlots);
                }
            }
            setCookOmsMapping(tempCookOmsMapping);
            setLoading(false);
        }).catch((err) => {setLoading(false)})
    }, [initialDate, finalDate, record]);
  
  useEffect(() => {
    const storedData = retrieveFromLocalStorage("cooksCalendar");
    if (storedData) {
      const cooksCalendarDates = JSON.parse(storedData);
      setInitialDate(cooksCalendarDates?.startDate);
      setFinalDate(cooksCalendarDates?.endDate);
    }
  }, [])
  const DataContainer = ({title = '', value = '---'}: {title: string, value: string}) => {
    return(
        <div>
          <b style={{ minWidth: "170px", display: "inline-block" }}>
            {title}
          </b>
          <span>{value}</span>
        </div>
    )
  };
  
  const DialogDetails = (props: any) => {
    let cook = props?.data?.cookDetails;
    const [data, setData] = useState<any>(null);
    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [redirectLink, setRedirectLink] = useState('')
    useEffect(() => {
      setLoadingData(true);
      if(props?.data?.referenceModelName === 'ONE_MEAL_SERVICE'){
        setRedirectLink(`/#/OneMealService/${props?.data?.referenceId}/show`);
        oneMealService.getOneMealService(props?.data?.referenceId).then((res: any) => {
          setData(res?.data);
          setLoadingData(false);
        })
      } else if(props?.data?.referenceModelName === 'TRIALS_V3'){
        setRedirectLink(`/#/Trials/${props?.data?.referenceId}/show`);
        trialsService.getTrialsById(props?.data?.referenceId).then((res: any) => {
          setData(res?.data);
          setLoadingData(false);
        })
      } else if(props?.data?.referenceModelName === 'COOK_SUBSCRIPTION_V3') {
        cookService.getSubscriptionForACook(props?.data?.referenceId).then((data: any) => {
          setRedirectLink(`/#/UserBundles/${data?.userBundleMappingId}/show/7`);
          userBundleService.fetchOneUserBundleMapping(data?.userBundleMappingId).then((res: any) => {
            setData(res?.data);
            setLoadingData(false);
          })
        })
      }
    }, [props?.data?.referenceId, props?.data?.referenceModelName]);
    return (
      <div>
        <Dialog
          onClose={props.handleClose}
          open={props.open}
          scroll={"paper"}
          maxWidth="xl"
        >
          <DialogTitle>
            <b>{props?.data?.referenceModelName}</b>
            <Button
                variant={'contained'}
                sx={{borderRadius: 30, fontSize: '10px', ml: 2}}
                size={'small'}
                component={Link}
                href={redirectLink}
            >
              {'View Full Details'}
            </Button>
          </DialogTitle>
          <DialogContent
            style={{ display: "flex", flexDirection: "column", width: "400px" }}
          >
            {!loadingData && props?.data?.referenceModelName === 'ONE_MEAL_SERVICE' &&
                data?.oneMealServiceAmount !== 0 &&
                data?.oneMealServiceAmount === data?.discount && (
                <Box
                    display={'flex'} justifyContent={'center'} alignItems={'center'}
                    sx={{
                      p: '4px',
                      backgroundColor: '#ffca69',
                      borderRadius: '12px',
                      mb: 1,
                    }}
                >
                  <Typography fontWeight={'600'}>
                    {'Combo Visit: 100% Coupon Applied'}
                  </Typography>
                </Box>
            )}
            <DataContainer title={'Status:'} value={data?.status} />
            {cook && (
              <div>
                <DataContainer title={'Cook Id:'} value={cook?.cookId} />
                <DataContainer title={'Cook Name:'} value={cook?.firstName + " " + cook?.lastName} />
                <DataContainer title={'Cook PhoneNo:'} value={data?.cookPhoneNumber} />
                <DataContainer title={'Onboarding Status:'} value={cook?.onboardingStatus} />
              </div>
            )}
            <DataContainer title={'Customer Name:'} value={data?.customerName} />
            <DataContainer title={'Customer PhoneNo:'} value={data?.customerPhoneNumber} />
            <DataContainer title={'City:'} value={data?.cityName} />
            <DataContainer title={'Area:'} value={data?.areaName} />
            <DataContainer title={'Locality:'} value={data?.localityName} />
            <DataContainer title={'Customer Address:'} value={data?.house?.address + " " + data?.house?.locality + " " + data?.house?.city} />
            <DataContainer title={'Total People:'} value={data?.totalPeople} />
            <DataContainer title={'Allocator Name:'} value={data?.allocatorName} />
            <DataContainer title={'Date:'} value={moment(data?.date).format('DD-MM-YYYY')} />
            <DataContainer title={'Time:'} value={data?.time} />
            <DataContainer title={'Check In Time:'} value={data?.checkInTime} />
            <DataContainer title={'Customer Feedback:'} value={data?.customerFeedback} />
            <DataContainer title={'Check Out Time:'} value={data?.checkOutTime} />
            <DataContainer title={'Customer Comments:'} value={data?.customerComments?.[0]?.comment} />
            <DataContainer title={'Customer Rating:'} value={data?.customerRating} />
          </DialogContent>
          <DialogActions style={{ marginTop: "12px" }}>
            <Button
              variant="contained"
              color={"error"}
              onClick={() => {
                props.handleClose(false);
              }}
              size={"large"}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  const calendarRefs = useRef<any>({});


  // Handler to sync scrolling across calendars
  const syncScroll = (scrollTop: any, sourceKey: any) => {
    Object.entries(calendarRefs.current).forEach(([key, ref]: any) => {
      // console.log(scrollTop, sourceKey);
      // console.log(key);
      // console.log(ref);
      if (key !== Number(sourceKey) && ref) {
        const scrollableDiv = ref.querySelector('.rbc-time-content');
        // console.log(scrollableDiv);
        if (scrollableDiv && scrollableDiv.scrollTop !== scrollTop) {
          scrollableDiv.scrollTop = scrollTop;
        }
      }
    });
  };

  useEffect(() => {
    const listeners: any = []; // Track listeners for cleanup

    Object.entries(calendarRefs.current).forEach(([key, ref]: any) => {
      if (ref) {
        const scrollableDiv = ref.querySelector('.rbc-time-content');
        if (scrollableDiv) {
          const handleScroll = () => syncScroll(scrollableDiv.scrollTop, key);
          scrollableDiv.addEventListener('scroll', handleScroll);
          listeners.push({ scrollableDiv, handleScroll });
        }
      }
    });

    // Cleanup all listeners on unmount
    return () => {
      listeners.forEach(({ scrollableDiv, handleScroll }: any) => {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      });
    };
  }, [cookOmsMapping]);

  const onEventClick = (data: any) => {
    if(data?.referenceId) {
      setDialog(true);
    }
    setDetails(data);
  };

  useEffect(() => {
    setCookOmsMapping({});
    getAllCooks();
    const interval = setInterval(() => {
      getAllCooks();
    }, 1000 * 60 * 5);
  return () => {
    clearInterval(interval);
  }
  }, [getAllCooks, initialDate, finalDate]);
  return (
    <Authenticated>
      <>
        <DialogDetails
          open={dialog}
          handleClose={() => setDialog(false)}
          data={details}
        />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "200%",
              minWidth: "0",
              overflowX: "scroll",
            }}
          >
            <Box display={'flex'} gap={'20px'}>    
                <DatePicker
                    mask=""
                    label="Select Start Date"
                    inputFormat="dd MMM yyyy"
                    value={initialDate}
                    onChange={(newValue: any) => {
                        setInitialDate(newValue);
                    }}
                    renderInput={(params) => {
                        return <TextField {...params} />;
                    }}
                    onAccept={
                      (newValue: any) => {
                        saveOrUpdateLocalStorage("cooksCalendar", {
                          startDate: moment(newValue),    
                        })
                      }
                    }
                />
                <DatePicker
                    mask=""
                    label="Select End Date"
                    inputFormat="dd MMM yyyy"
                    value={finalDate}
                    onChange={(newValue: any) => {
                        setFinalDate(newValue);
                    }}
                    renderInput={(params) => {
                        return <TextField {...params} />;
                    }}
                    onAccept={
                      (newValue: any) => {
                        saveOrUpdateLocalStorage("cooksCalendar", {
                          endDate: moment(newValue),
                        })
                      }
                    }
                />
            </Box>
            {/* <div
              style={{ display: "flex", height: "70vh", overflow: "scroll"}}
            >
              {
                Object.keys(cookOmsMapping).length !== 0 &&
                Object.entries(cookOmsMapping).map(([key, value]: any) => (
                    <div style={{ textAlign: "center" }} key={key} className={'Calendar__wrapper wrapper'}>
                        
                        <Kalend
                            kalendRef={props.kalendRef}
                            initialView={CalendarView.DAY}
                            disabledViews={[
                                CalendarView.AGENDA,
                                CalendarView.THREE_DAYS,
                                CalendarView.MONTH,
                                CalendarView.WEEK,
                            ]}
                            timeFormat={'12'}
                            onEventClick={onEventClick}
                            events={value}
                            initialDate={moment(key).format("YYYY-MM-DD")}
                            hourHeight={60}
                            disabledDragging={true}
                            onStateChange={props.onStateChange}
                            selectedView={props.selectedView}
                            showTimeLine={true}
                            isDark={false}
                            autoScroll={false}
                        />
                    </div>
                ))}
            </div> */}
            {
               loading ? <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100vw'}>
               <CircularLoader/>
             </Box> :
            <div style={{ display: "flex", height: "100%", overflow: "scroll" }}>
                {Object.keys(cookOmsMapping).length !== 0 &&
                  Object.entries(cookOmsMapping).map(([key, value]: any, index) => (
                    <div style={{ textAlign: "center", width: "200px" }} key={key} ref={(el) => (calendarRefs.current[key] = el)} className={"first-calendar"}>
                      <Calendar
                        localizer={localizer}
                        events={value}
                        defaultView="day"
                        views={["day"]} // Restrict to single day view
                        style={{ height: "100%", backgroundColor: "white" }} // Full height for better visibility
                        components={{
                          toolbar: CustomToolbar, // Use custom toolbar to hide navigation buttons
                        }}
                        step={60}         // Set each slot to 30 minutes
                        timeslots={1}
                        date={moment(key).format("YYYY-MM-DD")}
                        onSelectEvent={onEventClick}
                        eventPropGetter={eventStyleGetter}
                        min={new Date(2024, 0, 1, 5, 0)}// Start view from 5 AM
                        max={new Date(2024, 0, 1, 23, 59)} // End view at 11:59 PM
                      />
                    </div>
                  ))}
              </div>
            }
            <Box display={'flex'} flexDirection={'row'}>
              <Box m={2} display={'flex'} flexDirection={'column'}>
                {[legends[0], legends[1]]?.map((eachLegend) => (
                    <Box sx={{display: 'flex', alignItems: 'center'}} id={eachLegend?.label}>
                      <Box sx={{width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1}} m={0.5} mr={1} />
                      <Typography fontSize={12}>
                        {eachLegend?.label}
                      </Typography>
                    </Box>
                ))}
              </Box>
              <Box m={2} display={'flex'} flexDirection={'column'}>
                {[legends[2], legends[3]]?.map((eachLegend) => (
                    <Box sx={{display: 'flex', alignItems: 'center'}} id={eachLegend?.label}>
                      <Box sx={{width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1}} m={0.5} mr={1} />
                      <Typography fontSize={12}>
                        {eachLegend?.label}
                      </Typography>
                    </Box>
                ))}
              </Box>
              <Box m={2} display={'flex'} flexDirection={'column'}>
                {[legends[4]]?.map((eachLegend) => (
                    <Box sx={{display: 'flex', alignItems: 'center'}} id={eachLegend?.label}>
                      <Box sx={{width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1}} m={0.5} mr={1} />
                      <Typography fontSize={12}>
                        {eachLegend?.label}
                      </Typography>
                    </Box>
                ))}
              </Box>
            </Box>
          </div>
      </>
    </Authenticated>
  );
};
export default CookPartTimeCalendar;
