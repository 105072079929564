
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { OneMealService } from '../../services/OneMealService';
import EventBus from '../../EventBus';
import { EventBusEventsEnum } from '../../enums/EventBusEventsEnum';
import EditMealMapDialog from './EditMealMapDialog';
import { useRefresh } from 'react-admin';
import { CustomSnackbarTypes } from './CustomSnackBar';



const oneMealService = new OneMealService();

function MealMapTable(props : any) {
    const[dishData, setDishData] = useState<any>([]);
    const[openEditDish, setOpenEditDish] = useState<boolean>(false);
    const[dishToEdit, setDishToEdit] = useState<any>();
    const refersh = useRefresh();

    function editDish(dish: any){
        setOpenEditDish(true);
        setDishToEdit(dish);
    }


    const removeDishFormMealMap = (dishId : number) =>{
        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: "Are you sure you want to remove this Dish?",
            onClose: async (confirmed: boolean) => {
              if (confirmed) {
                let payload = {
                    addedDishes: dishData.filter((dish : any)=> dish?.id !== dishId)
                }
                await oneMealService.changeMealMap(props?.omsId,payload);
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Removed Successfully",
                    type: CustomSnackbarTypes.SUCCESS,
                });
                refersh();
              }
            },
            title: "Confirmation"
        });
    }

    
    useEffect(()=>{
        if(props.mealMap && props.mealMap.length > 0){
            setDishData(props?.mealMap.map ((dish: any) => {
                if(!dish.dishId){
                    return {
                        // custom dish
                        id : dish?.id,
                        dishName: dish?.dishName,
                        dishCount: dish?.dishCount,
                        dishType : dish?.dishType
                    }
                }
                return {
                    id : dish?.id,
                    dishId: dish?.dishId,
                    dishName: dish?.dishName,
                    dishCount: dish?.dishCount,
                    dishType : dish?.dishType
                }

            } ))
        }
    },[props?.mealMap])
  return (
    <>
    <TableContainer component={Paper} sx={{height:"100%"}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Dish Name</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Edit</TableCell>
                        <TableCell>Remove</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props?.mealMap && props?.mealMap.length && props?.mealMap?.map((dish: any, i: number) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell scope="row">{i+1}</TableCell>
                                <TableCell align="left">{dish?.dishName}</TableCell>
                                <TableCell align="left">{dish?.dishCount}</TableCell>
                                <TableCell align="left"><IconButton color="primary" onClick={()=>editDish(dish)}><EditRoundedIcon color="primary"/></IconButton></TableCell>
                                <TableCell align="left"><IconButton color="error" onClick={()=>removeDishFormMealMap(dish?.id)}><DeleteRoundedIcon color="error"/></IconButton></TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <EditMealMapDialog data={props?.mealMap} dish ={dishToEdit} open={openEditDish} callback={()=>{setOpenEditDish(false)}}/>   
        </>
  )
}

export default MealMapTable
