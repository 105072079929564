import { Close } from '@mui/icons-material';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { FoodCategory } from '../../enums/FoodCatergory';
import { OneMealService } from '../../services/OneMealService';
import EventBus from '../../EventBus';
import { EventBusEventsEnum } from '../../enums/EventBusEventsEnum';
import { useRefresh } from 'react-admin';
import { CustomSnackbarTypes } from './CustomSnackBar';



interface InputProps {
    data: any;
    omsId: number;
    open: boolean;
    callback: (data: any) => void;
}
const oneMealService = new OneMealService();
function AddMealMapDialog(props: InputProps) {
    let [dish,setDish] = useState<any>();
    let [dishes,setDishes] = useState<any>([]);
    let [dishCount,setDishCount] = useState<number>(1);
    let [foodCategory,setFoodCategory] = useState<FoodCategory>(FoodCategory.CURRY);
    let [dishType,setDishType] = useState<string>('normal');
    let [customDishName,setCustomDishName] = useState<string>('');
    const refersh = useRefresh();
    

    function handleClose() {
        props.callback(null);
    }

    function addDish() {
        let newDish = null;
        if(dishType === 'normal'){
            if(props?.data.length > 0){
                for (const oldDish of props.data) {
                    if (oldDish.dishId && oldDish.dishId === dish.id) {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Dish already exists",
                            type: CustomSnackbarTypes.ERROR,
                        });
                        return;
                    }
                    if (!oldDish.dishId && oldDish?.dishName?.toLowerCase().trim() === dish?.title?.toLowerCase().trim()) {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Dish already exists",
                            type: CustomSnackbarTypes.ERROR,
                        });
                        return;
                    }
                }
            }
            newDish = dishes.find((element : any) => element?.id === dish.id);
            if(!newDish){
                return;
            }
        }
        else {
            if(props?.data.length > 0){
                for (const oldDish of props.data) {
                    if (!oldDish.dishId && oldDish?.dishName?.toLowerCase().trim() === customDishName?.toLowerCase().trim()) {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Dish already exists",
                            type: CustomSnackbarTypes.ERROR,
                        });
                        return;
                    }
                }
            }
        }
        let dishToAdd = {
            dishId: dishType === 'normal' ? newDish.id : undefined,
            dishName: dishType === 'normal' ? newDish.name : customDishName,
            dishCount: dishCount,
            dishType: dishType === 'normal' ? newDish.dishType : foodCategory
        }

        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: "Are you sure you want to add this Dish?",
            onClose: async (confirmed: boolean) => {
              if (confirmed) {
                let payload = {
                    addedDishes: [...props?.data, dishToAdd]
                }
                await oneMealService.changeMealMap(props?.omsId,payload);
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Added Successfully",
                    type: CustomSnackbarTypes.SUCCESS,
                });
                setDish(null);
                setCustomDishName('');
                refersh(); 
        props.callback(null);
              }
            },
            title: "Confirmation"
        });
    }
    
    useEffect(() => {
        const fetchDishes = async () => {
            const dishes = await oneMealService.getDishByFoodCategory(
                100, 
                0, 
                { "forChefit": true, "dishType": foodCategory },
                {}
            );
            setDishes(dishes.data);
            setDish(null);
        };
        fetchDishes();
    }, [foodCategory]);
  return (
    <>
     <Dialog
            onClose={handleClose}
            open={props.open}
            scroll={"paper"}
            maxWidth="sm"
            fullWidth
        >
                <DialogTitle>Add Dish</DialogTitle>
                <DialogContent>
                    <IconButton onClick={() => props.callback(null)} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
                    <FormControl style={{width:'100%', marginBottom: "20px" }}>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Box fontSize={'1.2rem'}>{dishType === 'normal' ? 'Select Category' : 'Enter Dish Name'}</Box>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={dishType}
                                    onChange={(event)=> setDishType((event.target as HTMLInputElement).value)}
                                    row
                                >
                                    <FormControlLabel value="normal" control={<Radio />} label="NORMAL" />
                                    <FormControlLabel value="custom" control={<Radio />} label="CUSTOM" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Select
                                labelId="type"
                                id="type"
                                label="type"
                                value={foodCategory}
                                sx={{marginTop:"2rem"}}
                                onChange={(event: any) => {
                                    setFoodCategory(event.target.value as FoodCategory);
                                }}
                            >
                                {Object.values(FoodCategory).map((d: any) => (
                                    <MenuItem key={d} value={d}>
                                        {d}
                                    </MenuItem>
                                ))}
                        </Select>
                        {
                            dishType === 'normal' ? <>
                            {
                                dishes && dishes.length > 0 && (
                                    <Autocomplete
                                        id="tags-standard"
                                        value={dish}
                                        options={dishes?.map((dish: any) => {
                                        return { title: dish?.name, id: dish?.id };
                                        })}
                                        onChange={(e, v: any) => setDish(v)}
                                        getOptionLabel={(option: any) => option.title}
                                        renderInput={(params) => (
                                        <TextField {...params} variant="standard" label="Select Dish" placeholder="Enter Your Dish" />
                                        )}
                                    />
                                )
                            }
                        </> :
                        <>
                            <TextField
                                id="outlined-textarea"
                                label="Dish Name"
                                value={customDishName || ""}
                                rows={4}
                                fullWidth
                                onChange={(e)=> setCustomDishName(e.target.value)}
                            />

                        </>
                        }
                        {
                            ((dish && foodCategory === FoodCategory.BREAD) ||  dishType === 'custom' )&&
                            <Select
                                labelId="Count"
                                id="Count"
                                label="Count"
                                onChange={(event: any) => {
                                setDishCount(event.target.value);
                                }}
                                value={dishCount}
                                sx={{marginTop:"1.2rem"}}
                            >
                                {Array.from({ length: 40 }, (_, index) => index + 1).map((count) => (
                                    <MenuItem key={count} value={count}>
                                        {count}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    </FormControl>
                </DialogContent>
                <DialogActions style={{ marginTop: "12px" }}>
                    <Button
                        variant="contained"
                        onClick={() => {addDish()}}
                        size={"large"}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color={"error"}
                        onClick={() => {props.callback(null)}}
                        size={"large"}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
    </>
  )
}

export default AddMealMapDialog
