import { Box, Button, Card, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  NumberField, RaRecord, SimpleShowLayout,
  TextField,
  useRecordContext,
  useRefresh
} from "react-admin";
import CheckInAndOutImages from "../Common/CheckInAndOutImages";
import CustomReferenceField from "../Common/CustomReferenceField";
import React, { useCallback, useEffect, useState } from "react";
import { OneMealService } from "../../services/OneMealService";
import ChefForPartStatusEnum from "../../enums/ChefForPartStatusEnum";
import CallButton from "../Common/CallButton/CallButton";
import { UserType } from "../../enums/common";
import JsonViewer from "../Common/JsonViewer";
import ImagePreviewer from "../Common/ImagePreviewer";
import HorizontalTimeline from "../Common/HorizontalTimeline";
import { useAppSelector } from "../../store/hooks";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import { UserGroupsEnum } from "../../enums/UserGroupsEnum";
import { OneMealServiceStatus } from "../../enums/OneMealServiceStatus";
import MealMapTable from "../Common/MealMapTable";
import AddMealMapDialog from "../Common/AddMealMapDialog";

const restrictedEditState = ["FULFILLED", "CANCELLED"];
const oneMealService = new OneMealService();
const OneMealServiceMetaShow = () => {
  const record = useRecordContext();
  let [isNew, setIsNew] = useState<boolean>(false);
  let [loading, setLoading] = useState<boolean>(true);
  let [openAdd,setOpenAdd] = useState<boolean>(false);
  const [pageSize] = useState(10);
  const userDetails = useAppSelector((state) => state.userDetails.value);
  const refresh = useRefresh();

  let groupsArray: string[] = userDetails.adminUser.accessGroups;
  const getData = useCallback(
    (pageNo: number) => {
      setLoading(true);
      oneMealService
        .getAllOneMealServiceForHouse(record?.houseId, pageSize, pageSize * pageNo, {
          order: "DESC",
          field: "createdAt",
        }, {
          status: ChefForPartStatusEnum.FULFILLED,
        })
        .then((data) => {
          if (data?.data?.length !== 0) {
            setIsNew(false);
            setLoading(false);
          } else {
            setIsNew(true);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [record?.houseId, pageSize]
  );

  function hasEditAccess() {
    return GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN]) || record?.status === OneMealServiceStatus.PENDING;
  }

  useEffect(() => {
    getData(0);
  }, [getData]);

  return (
    <>
      <FunctionField
        render={(record: RaRecord) => {
          return (
            <>
              {(!restrictedEditState.includes(record?.status) && hasEditAccess()) && <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <EditButton />
              </Box>}
            </>
          );
        }}
      />
      {isNew && !loading && (
        <Chip label={`New Customer (No Fulfilled One Meal Services Yet)`} sx={{ backgroundColor: '#90ee8f' }} />
      )}
      {record?.hasOptedPayAfterService && (
        <Chip
          sx={{ width: '100%', my: 1, backgroundColor: '#ffac5f' }}
          label={`Opted for Pay After Service`}
        />
      )}
      {
        record?.razorpayOrderId&&(
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Chip
              sx={{ width: '20%', my: 1, backgroundColor: '#ffac5f' }}
              label={`Razor Pay Order Generated`}
            />
          </Box>
        )
      }
      <div style={{ display: "grid", gridTemplateColumns: "25% 30% 45%" }}>
        <SimpleShowLayout>
          <FunctionField label="id" render={(record: RaRecord) => {
            if (!record?.rescheduledFrom) {
              return (
                <div>{record?.id}</div>
              )
            }
            else{
              return (
                <div>{record?.id} (Rescheduled From
                <CustomReferenceField
                  label=""
                  listName={"OneMealService"}
                  source={"rescheduledFrom"}
                />
                )</div>
              )
            }
          }} />
          <TextField source="status" />
          {record?.clientId && <FunctionField label="Source of booking" render={(record: RaRecord) => {
            if (record?.clientId === 2) {
              return (
                <div>CENTAURUS</div>
              )
            }
            else if (record?.clientId === 4) {
              return (
                <div>CUSTOMER APP</div>
              )
            }
            else if (record?.clientId === 9) {
              return (
                <div>WEBSITE</div>
              )
            }
          }} />}
          <TextField source="paymentStatus" />
          <TextField source="hasOptedPayAfterService" />
          <TextField source="oneMealServiceAmount" />
          <FunctionField
            label="Visit Time"
            render={(record: { date: any; time: any }) =>
              `${record?.date ? moment(record.date).format('DD/MM/YYYY') : 'Not Available'} ${record?.time || ''}`
            }
          />
          <TextField source="oneMealServiceCookCut" />
          <TextField label="Discount Applied" source="discount" />
          <TextField label="Surge Amount" source="surgeAmount" />
          <TextField label="Express Amount" source="expressAmount" />
          {record.paymentStatus === "PAID" && <FunctionField
            label="Amount Paid"
            render={(record: RaRecord) =>
              `${(parseFloat(record?.oneMealServiceAmount) - parseFloat(record?.discount)).toFixed(2)}`
            }
          />}
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField label="Bundle Plan" source="bundlePlan" />
          <CustomReferenceField
            label="User Bundle"
            listName={"UserBundles"}
            source={"userBundleMappingId"}
          />
          <CustomReferenceField
            label="House"
            listName={"Houses"}
            source={"houseId"}
          />
          <FunctionField
            label="Address"
            render={(record: {
              house: { address: any; cityName: any; localityName: any; areaName: any; };
            }) =>
              [record?.house?.address, record?.house?.localityName, record?.house?.areaName, record?.house?.cityName]?.filter((each) => each !== null).join(', ')
            }
          />
          <TextField source="customerName" />
          <TextField source="customerRating" />
          <TextField source="customerFeedback" />

        </SimpleShowLayout>
        <SimpleShowLayout>
          <Card
            style={{
              overflow: "auto",
              borderRadius: "4px",
              height: "5rem",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
              backgroundColor: '#ffeb3b',
              color: "#000",
            }}
            elevation={0}
          >
            <SimpleShowLayout>
              <TextField source="editNotes" label={<span style={{ color: "#525252" }}>CUSTOMER NOTES</span>} />
            </SimpleShowLayout>
          </Card>
          <Card
            style={{
              overflow: "auto",
              borderRadius: "4px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
              color: "#000",
            }}
            elevation={0}
          >
            {
              record?.mealMap.length ? 
              <SimpleShowLayout>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <span style={{ color: "#525252" }}>MEAL MAP</span>
                  <Button onClick={()=>setOpenAdd(true)}>Add Dish</Button>
                </Box>
                <MealMapTable mealMap = {record?.mealMap} omsId = {record?.id}/>
              </SimpleShowLayout> : 
              <SimpleShowLayout>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <span style={{ color: "#525252" }}>NO MEAL MAP</span>
                  <Button onClick={()=>setOpenAdd(true)}>Add Dish</Button>
                </Box>
              </SimpleShowLayout>
            }
          </Card>
          <TextField source={"sector"} />
          <TextField source={"cancellationReason"} />
          <TextField source={"cancellationSubReason"} />
          <TextField source={"rescheduleReason"} />
          <TextField source={"rescheduleSubReason"} />
          <TextField source={"cookRejectionReason"} />
          {record?.house?.lat !== null && record?.house?.long !== null && (
            <div>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${record?.house?.lat},${record?.house?.long}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to google maps
              </a>
            </div>
          )}
        </SimpleShowLayout>
        <SimpleShowLayout>
          <NumberField source="totalPeople" />
          <FunctionField
            label="No of allowed dishes"
            render={(record: any ) => Number(record.noOfAllowedDishes)}
          />
          <TextField source="slotName" />
          <TextField source="genderPreference" />
          <CustomReferenceField
            label="Area Id"
            listName={"Areas"}
            source={"areaId"}
          />
          <CustomReferenceField
            label="City Id"
            listName={"Cities"}
            source={"cityId"}
          />
          <CustomReferenceField
            label="Locality Id"
            listName={"Localities"}
            source={"localityId"}
          />
          <CustomReferenceField
            label="Cook"
            listName={"Cooks"}
            source={"cookId"}
          />
          <CustomReferenceField
            label="Cx Selected Cook"
            listName={"Cooks"}
            source={"cxSelectedCookId"}
          />
          <FunctionField
            emptyText="Not Available"
            label="Cook Name"
            render={(record: RaRecord) => {
              if (!record?.cook?.cookFullProfile?.firstName) return `Not Available`
              return `${record?.cook?.cookFullProfile?.firstName} ${record?.cook?.cookFullProfile?.lastName}`;
            }
            }
          />
          <CallButton
            heading={'Cook phone number'}
            toPhoneNumber={record?.cook?.mobile}
            userType={UserType.COOK}
          />
          {/* <TextField label="Cook Phone Number" source="cook.mobile" /> */}
          <BooleanField source="cookAcknowledged" />
          <CustomReferenceField
            label="Allocator Id"
            listName={"AdminUsers"}
            source={"allocator"}
          />
          <TextField source="allocatorName" />
          <TextField source="allocatorEmail" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <CallButton
            heading={'Customer phone number'}
            toPhoneNumber={record?.customerPhoneNumber}
            userType={UserType.CUSTOMER}
          />
          {/* <TextField source="customerPhoneNumber" /> */}
          <TextField source="adminNotes" />
          <NumberField label="Cook Accepted" source="numberOfCookAccepted" />
          <NumberField
            label="Shortlisted Cook"
            source="numberOfCookAllocated"
          />
          <TextField
            label={"Not Converted reason"}
            source={"notConvertedReason"}
          />
          <TextField label="Cancelled By" source="cancelledBy" />
          <TextField label="Cancelled By Email" source="cancelledByEmail" />
          <TextField emptyText="" source="deniedBy" />
          <TextField emptyText="" source="deniedByType" />
          <CustomReferenceField
            label="Owner Id"
            listName={"AdminUsers"}
            source={"owner"}
          />
          <TextField source="ownerName" />
          <TextField source="ownerEmail" />

          <FunctionField
            label="Updated At"
            render={(record: RaRecord) =>
              `${record?.updatedAt ? moment(record?.updatedAt).format('DD/MM/YYYY hh:mm A') : 'Not Available'}`
          }
          />
          <FunctionField
            label="Created At"
            render={(record: RaRecord) =>
              `${record?.createdAt ? moment(record?.createdAt).format('DD/MM/YYYY hh:mm A') : 'Not Available'}`
          }
          />
          <FunctionField
            label="Payment Status Updated At"
            render={(record: RaRecord) =>
              `${record?.paymentStatusLastUpdatedAt? moment(record?.paymentStatusLastUpdatedAt).format('DD/MM/YYYY hh:mm A') : 'Not Available'}`
          }
          />
          <FunctionField
            label="Pre Checkin At"
            render={(record: RaRecord) =>
              `${
                record?.preCheckedInAt
                  ? moment(record?.preCheckedInAt).format('DD/MM/YYYY hh:mm A') + ` (${record?.preCheckedInType})`
                  : 'Not Available'
              }`
            }
          />
          <FunctionField
            label="Check In"
            render={(record: RaRecord) =>
              `${record?.checkInTime ? moment(record?.checkInTime).format('DD/MM/YYYY hh:mm A') : 'Not Available'}`
          }
          />
          {/* Uncommented and corrected the Check Out field */}
          <FunctionField
            label="Check Out"
            render={(record: RaRecord) =>
              `${record?.checkOutTime ? moment(record?.checkOutTime).format('DD/MM/YYYY hh:mm A') : 'Not Available'}`
          }
          />
          <FunctionField
            label="Cancelled At"
            render={(record: RaRecord) =>
              record?.cancelledAt ? <div>{moment(record?.cancelledAt).format('DD/MM/YYYY hh:mm A')}</div> : <div>Not Available</div>
            }
          />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <Card
            style={{
              overflow: "auto",
              borderRadius: "4px",
              height: "15rem",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
            }}
            elevation={0}
          >
            <SimpleShowLayout>
              <ArrayField label="Customer Preferences according to Cook" source="cxPreferences">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="question" />
                  <TextField source="answer" />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Card>
          <Card
            style={{
              overflow: "auto",
              borderRadius: "4px",
              height: "12rem",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
            }}
            elevation={0}
          >
            <SimpleShowLayout>
              <ArrayField source="supportComments">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="comment" />
                  <TextField source="commenterEmail" />
                  <DateField source="timestamp" showTime />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Card>
          <Card
            style={{
              overflow: "auto",
              borderRadius: "4px",
              height: "12rem",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
            }}
            elevation={0}
          >
            <SimpleShowLayout>
              <ArrayField source="customerComments">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="comment" />
                  <TextField source="commenterEmail" />
                  <DateField source="timestamp" showTime />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Card>
          <Card
            style={{
              overflow: "auto",
              borderRadius: "4px",
              height: "12rem",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
            }}
            elevation={0}
          >
            <SimpleShowLayout>
              <ArrayField label={'Allocation/Tracker Comments'} source="allocationComments">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="comment" />
                  <TextField source="commenterEmail" />
                  <DateField source="timestamp" showTime />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Card>
          <Card
            style={{
              overflow: "auto",
              borderRadius: "4px",
              height: "12rem",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
            }}
            elevation={0}
          >
            <SimpleShowLayout>
              <ArrayField source="chefFeedback">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="comment" />
                  <TextField source="commenterEmail" />
                  <DateField source="timestamp" showTime />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Card>
        </SimpleShowLayout>
      </div>
      <SimpleShowLayout>
        <Box>
            <Typography fontFamily={'inherit'} fontWeight={500} my={'16px'}>Time Line</Typography>
            <HorizontalTimeline record={record}/>
        </Box>
        <Box>
          <Typography fontFamily={'inherit'} fontWeight={500} my={'16px'}>Customer Ratings</Typography>
          {record?.customerRatings && record?.customerRatings?.length !== 0 &&
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Overall Ratings</TableCell>
                    <TableCell align="center">Behaviour Rating</TableCell>
                    <TableCell align="center">Cleaniness Rating</TableCell>
                    <TableCell align="center">Food Quality Rating</TableCell>
                    <TableCell align="center">Comments</TableCell>
                    <TableCell align="center">Photos</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record?.customerRatings?.map((row: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell align="center" sx={{ fontWeight: 500 }}>
                        <Chip label={row?.overallRating} />
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          {row?.behaviourRating && <Chip sx={{ mb: '4px' }} label={row?.behaviourRating} />}
                          {row?.ratingTags && row?.ratingTags["Behavior related"]?.length !== 0 &&
                            row?.ratingTags["Behavior related"]?.map((val: any, ind: number) => {
                              return (
                                <Box key={ind} display={'flex'} flexDirection={'column'} mb={'4px'}>
                                  <Chip label={val} />
                                </Box>
                              )
                            })}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          {row?.cleaninessRating && <Chip sx={{ mb: '4px' }} label={row?.cleaninessRating} />}
                          {row?.ratingTags && row?.ratingTags["Hygiene Related"]?.length !== 0 &&
                            row?.ratingTags["Hygiene Related"]?.map((val: any, ind: number) => {
                              return (
                                <Box key={ind} display={'flex'} flexDirection={'column'} mb={'4px'}>
                                  <Chip label={val} />
                                </Box>
                              )
                            })}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          {row?.foodQualityRating && <Chip sx={{ mb: '4px' }} label={row?.foodQualityRating} />}
                          {row?.ratingTags && row?.ratingTags["Food related"]?.length !== 0 &&
                            row?.ratingTags["Food related"]?.map((val: any, ind: number) => {
                              return (
                                <Box key={ind} display={'flex'} flexDirection={'column'} mb={'4px'}>
                                  <Chip label={val} />
                                </Box>
                              )
                            })}
                        </Box>
                      </TableCell>
                      <TableCell align="center">{row?.comments}</TableCell>
                      <TableCell align="center">
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'5px'}>
                          {row?.photos && row?.photos?.length !== 0 &&
                            row?.photos?.map((photo: any, ind: number) => (
                              <Box sx={{ cursor: 'pointer' }}>
                                <ImagePreviewer key={ind} value={photo} width={"70px"} height={"70px"} />
                              </Box>
                            ))}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Box>
      </SimpleShowLayout>
      <CheckInAndOutImages />
      <JsonViewer data={record} />
      <AddMealMapDialog data={record?.mealMap} open={openAdd} callback={()=>{setOpenAdd(false); refresh()}} omsId={Number(record?.id)}/>
    </>
  );
};

export default OneMealServiceMetaShow;
