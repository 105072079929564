import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, Select} from '@mui/material';
import React, { useState } from 'react'
import EventBus from '../../EventBus';
import { EventBusEventsEnum } from '../../enums/EventBusEventsEnum';
import { OneMealService } from '../../services/OneMealService';
import { useRefresh } from 'react-admin';
import { CustomSnackbarTypes } from './CustomSnackBar';

interface InputProps {
    data: any;
    dish:any;
    open: boolean;
    callback: (data: any) => void;
}

const oneMealService = new OneMealService();
function EditMealMapDialog(props : InputProps) {

    const [dishCount,setDishCount] = useState<number>(props?.dish?.dishCount);
    const refresh = useRefresh();

    function handleClose() {
        props.callback(null);
    }
    function editDish() {
        let updateDishArray = props?.data?.map((dish: any) => {
            if(dish.id === props?.dish?.id){
                return {...dish, dishCount: dishCount}
            }
            return dish;
        })
        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: "Are you sure you want to edit this Dish?",
            onClose: async (confirmed: boolean) => {
              if (confirmed) {
                let payload = {
                    addedDishes: updateDishArray
                }
                await oneMealService.changeMealMap(props?.dish?.referenceId,payload);
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Changed Successfully",
                    type: CustomSnackbarTypes.SUCCESS,
                });
                props.callback(updateDishArray);
                refresh();
              }
            },
            title: "Confirmation"
        });

    }

return (
    <>
       <Dialog
            onClose={handleClose}
            open={props.open}
            scroll={"paper"}
            maxWidth="sm"
            fullWidth
        >
                <DialogTitle>Edit Dish Quantity</DialogTitle>
                <DialogContent>
                    <IconButton onClick={() => props.callback(null)} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
                    <FormControl style={{width:'100%', marginBottom: "20px" }}>
                        <Box fontSize={'1.2rem'}>Dish Name : {props?.dish?.dishName}</Box>
                        <Box fontSize={'1.2rem'} marginTop={'1rem'}>Dish Count : {props?.dish?.dishCount}</Box>

                        <Select
                            labelId="Count"
                            id="Count"
                            label="Count"
                            defaultValue={props?.dish?.dishCount}
                            onChange={(event: any) => {
                               setDishCount(event.target.value);
                            }}
                            sx={{marginTop:"2rem"}}
                        >
                            {Array.from({ length: 40 }, (_, index) => index + 1).map((count) => (
                                <MenuItem key={count} value={count}>
                                    {count}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions style={{ marginTop: "12px" }}>
                    <Button
                        variant="contained"
                        onClick={() => editDish()}
                        size={"large"}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color={"error"}
                        onClick={() => {props.callback(null)}}
                        size={"large"}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
    </>
  )
}

export default EditMealMapDialog
